import * as Sentry from '@sentry/sveltekit';
import type { HandleClientError } from '@sveltejs/kit';

const isProduction = import.meta.env.PROD;
if (isProduction) {
	Sentry.init({
		dsn: 'https://96b648e1516f4ac5cfa1c2d7ae0c2c8c@o4506372237295616.ingest.us.sentry.io/4506372238475264',
		tracesSampleRate: 1,
		attachStacktrace: true,
		ignoreErrors: [
			'TypeError: Failed to fetch',
			'TypeError: Importing a module script failed.',
			/TypeError: Failed to fetch dynamically imported module.*/,
			/Error: Unable to preload CSS for .*/,
			/AccessDeniedError: You do not have enough permissions.*/,
			/TypeError: cancelled/
		]
	});
}

const emptyHandleError: HandleClientError = ({ error }) => {
	// @ts-expect-error this conforms to the default implementation (including this ts-expect-error)
	// eslint-disable-next-line no-console
	console.error(error && error.stack);
};
export const handleError = Sentry.handleErrorWithSentry(emptyHandleError);
