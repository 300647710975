import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90')
];

export const server_loads = [0,2,4,6,8,9,10,13,14,17,21,22];

export const dictionary = {
		"/": [~24],
		"/calendar": [~25,[2]],
		"/changelog": [~26,[3]],
		"/dashboard": [~27,[4]],
		"/delete-me": [28],
		"/invitation/[slug]": [~29],
		"/login": [~30],
		"/logout": [~31],
		"/password-reset/[slug]": [~32],
		"/patients": [~33,[5]],
		"/patients/[id]": [~34,[5,6]],
		"/patients/[id]/actions": [~35,[5,6,7]],
		"/patients/[id]/billing": [36,[5,6,8]],
		"/patients/[id]/billing/[iid]": [~37,[5,6,8]],
		"/patients/[id]/chart": [~38,[5,6,9]],
		"/patients/[id]/chart/dentition": [~55,[5,6,9]],
		"/patients/[id]/chart/endodontic": [56,[5,6,9]],
		"/patients/[id]/chart/periodontal": [57,[5,6,9]],
		"/patients/[id]/chart/[tid]": [39,[5,6,9,10]],
		"/patients/[id]/chart/[tid]/caries": [40,[5,6,9,10]],
		"/patients/[id]/chart/[tid]/conditions": [41,[5,6,9,10]],
		"/patients/[id]/chart/[tid]/conditions/[cid]": [42,[5,6,9,10]],
		"/patients/[id]/chart/[tid]/diastema": [43,[5,6,9,10]],
		"/patients/[id]/chart/[tid]/extraction": [44,[5,6,9,10]],
		"/patients/[id]/chart/[tid]/extraction/[eid]": [45,[5,6,9,10]],
		"/patients/[id]/chart/[tid]/impacted": [46,[5,6,9,10]],
		"/patients/[id]/chart/[tid]/missing": [47,[5,6,9,10]],
		"/patients/[id]/chart/[tid]/movements": [48,[5,6,9,10]],
		"/patients/[id]/chart/[tid]/permanent": [~49,[5,6,9,10]],
		"/patients/[id]/chart/[tid]/primary": [50,[5,6,9,10]],
		"/patients/[id]/chart/[tid]/procedures": [51,[5,6,9,10]],
		"/patients/[id]/chart/[tid]/procedures/[pid]": [52,[5,6,9,10]],
		"/patients/[id]/chart/[tid]/restorations": [53,[5,6,9,10]],
		"/patients/[id]/chart/[tid]/restorations/[rid]": [54,[5,6,9,10]],
		"/patients/[id]/general": [~58,[5,6,11]],
		"/patients/[id]/general/appointments": [~59,[5,6,11,12]],
		"/patients/[id]/general/consent2": [60,[5,6,11,13]],
		"/patients/[id]/general/consent2/[cid]": [~61,[5,6,11,13]],
		"/patients/[id]/general/medical-history": [~62,[5,6,11]],
		"/patients/[id]/general/messages": [63,[5,6,11]],
		"/patients/[id]/general/prescription": [~64,[5,6,11,14]],
		"/patients/[id]/general/prescription/new": [~66,[5,6,11,14,15]],
		"/patients/[id]/general/prescription/[pid]": [~65,[5,6,11,14]],
		"/patients/[id]/general/profile": [~67,[5,6,11]],
		"/patients/[id]/timeline": [~68,[5,6]],
		"/patients/[id]/whiteboard/[wid]": [~69,[5,6]],
		"/register": [~70],
		"/settings": [~71,[16]],
		"/settings/account": [~72,[16]],
		"/settings/branches": [~73,[16]],
		"/settings/branches/[id]": [~74,[16]],
		"/settings/consent2": [~75,[16,17]],
		"/settings/consent2/new": [~77,[16,17]],
		"/settings/consent2/[id]": [~76,[16,17]],
		"/settings/payouts": [~78,[16,18]],
		"/settings/preferences": [79,[16,19]],
		"/settings/prescription": [~80,[16,20]],
		"/settings/prescription/new": [~82,[16,20]],
		"/settings/prescription/[id]": [~81,[16,20]],
		"/settings/price-list": [83,[16,21]],
		"/settings/price-list/[id]": [~84,[16,21]],
		"/settings/profile": [~85,[16]],
		"/settings/subscription": [~86,[16,22]],
		"/settings/subscription/change": [~87,[16,22]],
		"/settings/subscription/purchases": [~88,[16,22]],
		"/settings/subscription/sms": [~89,[16,22]],
		"/settings/users": [~90,[16,23]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';